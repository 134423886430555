import { Translation } from "@/components/Translation/Translation.component";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import clsx from "clsx";
import type { HeaderModel } from "../../services/api/content/models/header-model.types";
import { Link } from "../Link/Link.component";
type ImprintProps = {
  readonly brand: HeaderModel.Brand;
  readonly className?: string;
};
export function Imprint({
  brand,
  className
}: ImprintProps) {
  const brandLink = brand.url !== null ? <Link className="font-theme-bold hover:underline" href={brand.url} openInNewTab={true} position="brand-a" rel="noreferrer">
        {brand.name}
      </Link> : <span className="font-bold">{brand.name}</span>;
  return <p className={clsx("hidden select-none overflow-hidden text-xs uppercase text-inherit lg:block lg:h-2 print:block", className)} data-sentry-component="Imprint" data-sentry-source-file="Imprint.component.tsx">
      <Translation da={inlineJsx`En del af ${brandLink}`} de={inlineJsx`FinanzBusiness ist Teil der ${brandLink}`} en={inlineJsx`A part of ${brandLink}`} no={inlineJsx`En del av ${brandLink}`} sv={inlineJsx`En del av ${brandLink}`} data-sentry-element="Translation" data-sentry-source-file="Imprint.component.tsx" />
    </p>;
}